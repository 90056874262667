import Zalo from '../../../assets/icons/zalo-icon.svg';
import WhatApp from '../../../assets/icons/whatapp.svg';
import Phone from '../../../assets/icons/phone.png';
const ContactZalo = () => {
  return (
    <div className="fixed right-[5px] xl:right-[20px] bottom-[60px] xl:bottom-[80px] z-10 p-2 xl:p-3 cursor-pointer flex flex-col gap-4">
      <a
        href="https://id.zalo.me/account?continue=https%3A%2F%2Fchat.zalo.me%2F%3Fphone%3D0907968552"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Zalo} alt="zalo-icon" width={60} height={60} />
      </a>
      <a href="https://wa.me/+84907968552" target="_blank" rel="noopener noreferrer">
        <img src={WhatApp} alt="zalo-icon" width={60} height={60} />
      </a>
      <a href="tel:+84907968552" target="_blank" rel="noopener noreferrer">
        <img src={Phone} alt="phone-icon" width={60} height={60} />
      </a>
    </div>
  );
};

export default ContactZalo;
