import React, { Suspense } from 'react';
import ScrollToTop from 'components/atoms/ScrollToTop';
import ContactZalo from 'components/atoms/ContactZalo';

// import ScrollAnimation from 'react-animate-on-scroll';

function App() {
  const Header = React.lazy(() => import('components/molecules/Header'));
  const AboutUs = React.lazy(() => import('components/molecules/AboutUs'));
  const Menu = React.lazy(() => import('components/molecules/Menu'));
  const Feedback = React.lazy(() => import('components/molecules/Feedback'));
  const Service = React.lazy(() => import('components/molecules/Service'));
  const ContactUs = React.lazy(() => import('components/molecules/ContactUs'));
  const Footer = React.lazy(() => import('components/molecules/Footer'));
  const NewsPage = React.lazy(() => import('components/molecules/News'));
  return (
    <Suspense fallback={<></>}>
      <div className="App">
        <Header />

        {/* <ScrollAnimation animateIn="bounceInUp"> */}
        <AboutUs />
        {/* </ScrollAnimation> */}

        <Menu />
        <Feedback />
        <Service />
        <NewsPage />
        <ContactUs />
        <Footer />
        <ScrollToTop />
        <ContactZalo />
      </div>
    </Suspense>
  );
}

export default App;
