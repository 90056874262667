import ReactDOM from 'react-dom';
import { Routes, Route } from 'react-router-dom';
import './assets/styles/app.scss';
import reportWebVitals from './reportWebVitals';
import { HistoryRouter } from 'components/HistoryRouter';
import { history } from 'utils/history';
import App from 'App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Suspense } from 'react';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <HistoryRouter history={history}>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
