import { useEffect, useState } from 'react';
import IconArrowTop from 'assets/icons/ic-arrow-up.svg';

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return showTopBtn ? (
    <div
      className="flex justify-center items-center bg-blue-300 fixed rounded-[5px] right-[15px] xl:right-[30px] bottom-[15px] xl:bottom-[30px] z-10 p-2 xl:p-3 cursor-pointer"
      onClick={goToTop}
    >
      <img src={IconArrowTop} alt="Icon Arrow Top" />
    </div>
  ) : (
    <></>
  );
};

export default ScrollToTop;
